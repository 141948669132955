import React from "react"

import { HeaderTriangle, ContactHeaderWrapper } from "./Header.styles"
import NavigationComponent from "../Navigation/Navigation.component"

export default props => {
  return (
    <ContactHeaderWrapper>
      <NavigationComponent />
      <div className="wrapper">
        <h1>Privacy</h1>
      </div>
      <HeaderTriangle className="hide-mobile">
        <div className="triangle" />
        <div className="triangle-content-wrapper">
          {/* <div>
            <Img fixed={data.youtubers.childImageSharp.fixed} />
            For youtubers
          </div>
          <div>
            <Img fixed={data.companies.childImageSharp.fixed} />
            For companies
          </div> */}
        </div>
      </HeaderTriangle>
    </ContactHeaderWrapper>
  )
}
